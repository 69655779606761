exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-insight-tsx": () => import("./../../../src/templates/insight.tsx" /* webpackChunkName: "component---src-templates-insight-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-solution-tsx": () => import("./../../../src/templates/solution.tsx" /* webpackChunkName: "component---src-templates-solution-tsx" */),
  "component---src-templates-typage-tsx": () => import("./../../../src/templates/typage.tsx" /* webpackChunkName: "component---src-templates-typage-tsx" */),
  "slice---src-components-header-header-tsx": () => import("./../../../src/components/header/header.tsx" /* webpackChunkName: "slice---src-components-header-header-tsx" */),
  "slice---src-components-sections-footer-tsx": () => import("./../../../src/components/sections/footer.tsx" /* webpackChunkName: "slice---src-components-sections-footer-tsx" */)
}

